import React from "react";

import "./App.css";
import Logo from "../assets/img/logo.svg";
import Arrow from "../assets/img/arrow.png";
import { usePresenter } from "@hooks/usePresenter";

export const App = () => {
    const { allowText } = usePresenter();
    return (
        <div className="container">
            <img src={Logo} alt="logo" className="logo" />
            <img src={Arrow} alt="arrow" className="arrow" />
            <div className="text_box">
                <p id="allowText">{allowText}</p>
            </div>
        </div>
    );
};
